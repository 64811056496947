import React from "react"
import { Link } from "gatsby"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const CCLicense = ({title, attributionUrl, attributionName, ccLicenseSlug, ccLicenseName, ccLicenseUrl, }) => {

  const ccVersion = "4.0";
  ccLicenseSlug = ccLicenseSlug || "by-nc-sa";
  ccLicenseUrl = ccLicenseUrl || "http://creativecommons.org/licenses/by-nc-sa/4.0/";
  ccLicenseName = ccLicenseName || "Creative Commons Attribution - NonCommercial - ShareAlike 4.0 International License";

  return (
    <div className="credit-legal">
      <span {...{ 'xmlns:dct': "http://purl.org/dc/terms/" }} href="http://purl.org/dc/dcmitype/StillImage" property="dct:title" rel="dct:type">"{title}"</span> by <a {...{ 'xmlns:cc': "http://creativecommons.org/ns#" }} href={attributionUrl} property="cc:attributionName" rel="cc:attributionURL">{attributionName}</a> <span>is licensed under a </span><a rel="license" href={ccLicenseUrl}>{ccLicenseName}</a>
      <a rel="license" href={`http://creativecommons.org/licenses/${ccLicenseSlug}/${ccVersion}/`}>
        <img height="31" width="88" alt="Creative Commons License" src={`https://i.creativecommons.org/l/${ccLicenseSlug}/${ccVersion}/88x31.png`} />
      </a>
    </div >
  );
}

export default CCLicense
