import { slide as BurgerMenu } from 'react-burger-menu'
import React from "react"
import { Link } from "gatsby"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import "./Menu.css"

const Menu = ({ children }) => {

  const isMenuOpen = (state) => {
    if (state.isOpen) {
      trackCustomEvent({category: "interaction", action: "menu-opened"});
    } else {
      trackCustomEvent({category: "interaction", action: "menu-closed"});
    }
  };

  return (
    <div className="menu-wrap">
      <BurgerMenu right onStateChange={ isMenuOpen }>
        <Link className="menu-item" to="cities" onClick={e => {trackCustomEvent({category: "navigation", action: "cities", label: "menu"})}}>Cities</Link>
        <Link className="menu-item" to="tags" onClick={e => { trackCustomEvent({ category: "navigation", action: "tags", label: "menu" }) }}>Tags</Link>
        <Link className="menu-item" to="submit" onClick={e => {trackCustomEvent({category: "navigation", action: "submit", label: "menu"})}}>Submit a sign</Link>
        <Link className="menu-item" to="about" onClick={e => {trackCustomEvent({category: "navigation", action: "about", label: "menu"})}}>About</Link>
        <a className="menu-item" href="https://instagram.com/duetocovid19" onClick={e => {trackCustomEvent({category: "external-link", action: "instagram"})}}>Instagram</a>
        <a className="menu-item" href="https://twitter.com/duetocovid19" onClick={e => {trackCustomEvent({category: "external-link", action: "twitter"})}}>Twitter</a>
      </BurgerMenu>
    </div>
  );
}

export default Menu
