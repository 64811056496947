import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import CCLicense from '../components/CCLicense';

const Footer = () => (
  <footer>
    <div
      className="wrapper"
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `0 1.0875rem`,
      }}
    >
      <div className="menu">
        <Link className="menu-item" to="cities" onClick={e => { trackCustomEvent({ category: "navigation", action: "cities", label: "footer" }) }}>Cities</Link>
        <Link className="menu-item" to="tags" onClick={e => { trackCustomEvent({ category: "navigation", action: "tags", label: "footer" }) }}>Tags</Link>
        <Link className="menu-item" to="submit" onClick={e => { trackCustomEvent({ category: "navigation", action: "submit", label: "footer" }) }}>Submit a sign</Link>
        <Link className="menu-item" to="about" onClick={e => { trackCustomEvent({ category: "navigation", action: "about", label: "footer" }) }}>About</Link>
        <a className="menu-item" href="https://instagram.com/duetocovid19" onClick={e => { trackCustomEvent({ category: "external-link", action: "instagram", label: "footer" }) }}>Instagram</a>
        <a className="menu-item" href="https://twitter.com/duetocovid19" onClick={e => { trackCustomEvent({ category: "external-link", action: "twitter", label: "footer" }) }}>Twitter</a>
      </div>
      <div className="copyright">
        <p>The text of "Due to COVID-19" by <Link to="/about">Andrew Louis</Link> is licensed under a <a href="http://creativecommons.org/licenses/by-nc-sa/4.0/">Creative Commons Attribution - NonCommercial - ShareAlike 4.0 International License</a></p>
        <p>Individual photos are licensed either with permission from the contributor or under a Creative Commons license.</p>
      </div>
    </div>
  </footer>
)

export default Footer
