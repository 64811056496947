/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const longTitle = `${site.siteMetadata.title}: ${site.siteMetadata.description}`;
  const longDescription = description || longTitle;

  // Just use siteMetadata.title if blank title passed in
  let titleTemplate; 
  if (title === "") {
    title = longTitle;
    titleTemplate = `${longTitle}`;
  } else {
    titleTemplate = `%s | ${longTitle}`;
  }

  let socialMediaDescription = description || longTitle;

  let imageAbsoluteUrl;
  if (image) {
    imageAbsoluteUrl = `${site.siteMetadata.siteUrl}${image}`;
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: longDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: imageAbsoluteUrl,
        },
        {
          property: `og:description`,
          content: socialMediaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:image`,
          content: imageAbsoluteUrl,
        },
        {
          name: `twitter:description`,
          content: socialMediaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
