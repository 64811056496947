import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'


const Header = ({ siteTitle }) => (
  <StaticQuery
    query={graphql`
      query {
        hero: file(relativePath: { eq: "banner.jpg" }) {
          childImageSharp {
            fluid(quality: 41, maxWidth: 1920, duotone: {highlight: "#ffffff", shadow: "#ffffff", opacity: 50}) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}

  render={data => {
    const imageData = data.hero.childImageSharp.fluid
    return (
    <BackgroundImage
      Tag="header"
      fluid={imageData}
      backgroundColor={`#333333`}
      style={{
        backgroundSize: 'contain',
        backgroundRepeat: 'repeat'
      }}
    >
          <div
            style={{
              margin: `0 auto`,
              maxWidth: 960,
            }}
          >
            <div className="header-text">
              <h1>
                <Link
                  to="/"
                  style={{
                    color: `black`,
                    textDecoration: `none`,
                  }}
                >
                  {siteTitle}
                </Link>
              </h1>
              <h2 className="secondary">Documenting the signs of the pandemic</h2>
            </div>
          </div>
    </BackgroundImage>
    )
  }}
/>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
